import React from "react"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { Helmet } from "react-helmet"
import { Link, graphql } from "gatsby"

/* const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) =>  */

class Tags extends React.Component{
  render() {

    const { data } = this.props
    /*     const siteTitle = data.site.siteMetadata.title */
        const posts = data.allMarkdownRemark.edges
    const group = data.allMarkdownRemark.group

    return (
    <Layout location={this.props.location}>
      {/* <Helmet title={title} /> */}
      <SEO
          title="Tags"
          description="Reference tags"
          keywords={[`reference`, `tags`]}
        />
      <div className="blog-wrapper">
        <h2 className="font-bold my-2 is-hidden">Tags</h2>
        <ul>
          {group.map(tag => (
            <li key={tag.fieldValue}>
              <Link className="font-bold my-1 block" to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                {tag.fieldValue} ({tag.totalCount})
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Layout>
    )
  }
}

/* TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
} */

export default Tags

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000, filter: {
      frontmatter: {
        published: { eq: true }
      }
    }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`