import React from "react"
// eslint-disable-next-line
import style from '../stylesheets/main.scss'
/* import style from '../stylesheets/index.css' */
import Navi from '../components/navi';
import Footer from '../components/footer';
class Layout extends React.Component {
  render() {
/*     const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/` */
    const { children } = this.props
    return (
      <div class="w-1/1 lg:w-1/3">
        <Navi></Navi>
        <main>{children}</main>
        <Footer></Footer>
      </div>
    )
  }
}

export default Layout
